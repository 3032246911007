<template>
  <div class="how_item">
    <div class="inner">
      <h5>3 pasos simples para obtener un préstamo en efectivo	</h5>
      <ul class="how_ul">
        <li>
          <img src="@/assets/images/loanImg/icon_apply.png" alt="">
          <p class="p1">Descargar productos de préstamos</p>
          <p class="p2">
            Haga clic para descargar nuestros productos<br>
            de préstamos gratis
          </p>
        </li>
        <li>
          <img src="@/assets/images/loanImg/-s-icon_sign.png" alt="">
          <p class="p1">Firma electrónica</p>
          <p class="p2">Firma del acuerdo de préstamo Firma digital</p>
        </li>
        <li>
          <img src="@/assets/images/loanImg/icon_approved.png" alt="">
          <p class="p1">Obtenga aprobación y obtenga financiamiento</p>
          <p class="p2">
            Las solicitudes presentadas estarán sujetas<br>
            a aprobación Una vez aprobada la aprobación,<br>
            se emitirá un préstamo a su tarjeta.
          </p>
        </li>
      </ul>
    </div>
    <div class="inner">
      <h5>Sobre nosotros</h5>
      <p class="p2">Al servicio de las empresas de tecnología financiera de Colombia, nos centramos en formas financieras para proporcionar préstamos instantáneos personales a amigos
        que necesitan fondos para cubrir los gastos de emergencia de la gente y los gastos inesperados.
      </p>
      <br>
      <p class="p2">Nuestro objetivo es ofrecer a los clientes una plataforma financiera flexible, sencilla, segura, rápida y sin preocupaciones. No tenemos muchos umbrales y solo
        intentamos hacer clic en nuestro botón de aplicación para experimentar nuestro servicio.
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.how_item {
  .inner {
    width: 1200px;
    margin: 40px auto;
    h5 {
      font-size: 30px;
      color: #333333;
      margin-bottom: 70px;
      text-align: center;
    }
    .how_ul {
      padding-bottom: 50px;
      overflow: hidden;
      li {
        float: left;
        width: 33.3%;
        text-align: center;
        img {
          width: 140px;
          // height: 143px;
          margin-bottom: 30px;
        }
        .p1 {
          font-size: 22px;
          color: #333333;
          margin-bottom: 15px;
        }
      }
    }
    .p2 {
      font-size: 16px;
      color: #999999;
      line-height: 22px;
    }
  }
}
</style>
